/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2025-01-04 15:25:27
 * @FilePath: \h5-front\src\stores\app-store.js
 * @Description: app项目共同使用部分
 */

import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: 'appStore',
  state: () => ({
    globalLoading: false,
    isJumpProduct: false, // 是否需要直接跳转产品页面
    iframeFull: false //子项目所需iframe全屏状态
  }),

  persist: {
    paths: ['globalLoading', 'isJumpProduct','iframeFull'],
    storage: sessionStorage
  },

  getters: {},
  actions: {
    setGlobalLoading(val) {
      console.log(val)
      this.globalLoading = val
    },
    setIsJumpProduct(val) {
      this.isJumpProduct = val
    },

    setIframeFull(val) {
      console.log('全屏状态>>>>',val);
      this.iframeFull = val
    }
  }
})
