/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-12-19 15:34:01
 * @FilePath: \h5-front\src\views\pages\fast-app\fast-store.js
 * @Description:
 */

import { defineStore } from 'pinia'

const shortcuts = {
  //环控中心
  'menuName.controlCenter': {
    icon: 'wodeliucheng',
    link: '/greenhouse/environmentalControl',
    menuKey: ['environmentalControl', 'drug']
  },
  //设备总控
  'controlCenter.deviceControl': { icon: 'duozhanduibi', link: '', menuKey: [] },
  //水肥中心
  'menuName.waterFertilizerCenter': { icon: 'shuihaotongji', link: '', menuKey: [] },
  //喷雾系统
  'menuName.spraySystem': { icon: 'liuliangqushi', link: '', menuKey: [] },
  //视频监控
  视频监控: { icon: 'lishishuju', link: '', menuKey: [] },
  //组态大屏
  组态大屏: { icon: 'dunshuihaodianliang', link: '', menuKey: [] },
  //大屏监控
  大屏监控: { icon: 'shishishuju', link: '', menuKey: [] },
  //打药系统
  'menuName.drugSystem': { icon: 'dingdiantouyao', link: '/water-ferti/dosing-system', menuKey: ['drug'] },
  //阳台种菜机
  'menuName.balconyGarden': { icon: 'qingcai', link: '/water-ferti/vegetable-plant', menuKey: ['planting'] },
  //进取型水肥控制系统
  'menuName.inOutWaterFertilizer': {
    icon: 'qingcai',
    link: '/water-ferti/vegetable-plant',
    menuKey: ['inoutfertilizer']
  },
  //区域管理
  'menuName.zoneManage': { icon: 'wenshidapengguanli', link: '/base-house/base-manage', menuKey: ['管理员'] },

  //加温中心
  'menuName.heatingControl': { icon: 'zhongyangkongtiaokongzhiqi-weixuanzhong', link: '/water-ferti/heat-control', menuKey: ['heating'] }
}

/**
 * 根据名称数组筛选快捷方式对象
 * @param {Array} names 名称数组 例如 [ "打药系统", "阳台种菜机", ]
 * @returns {Array} 筛选后的快捷方式对象数组
 */
export function filterShortcuts(names) {
  const filteredShortcuts = []

  names.forEach((menuName) => {
    const shortcut = shortcuts[menuName]
    if (shortcut) {
      filteredShortcuts.push({
        name: menuName,
        icon: shortcut.icon,
        link: shortcut.link,
        menuKey: shortcut.menuKey
      })
    }
  })

  return filteredShortcuts
}

export const useFastStore = defineStore({
  id: 'fastStore',
  state: () => ({
    visibleMenuNames: [] //快捷应用所有显示的菜单
  }),
  persist: {
    paths: ['visibleMenuNames'],
    storage: sessionStorage
  },
  getters: {
    getPreviousRoute() {
      return this.previousRoute
    }
  },
  actions: {
    // 更新存储的菜单项名称数组
    updateVisibleMenuNames(menuNames) {
      this.visibleMenuNames = menuNames
    }
  }
})
