/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2025-01-20 16:32:35
 * @FilePath: \h5-front\src\router\home-page.js
 * @Description: 我的主页
 */

const sensorHistory = () => import('@/views/pages/sensor-group/history/sensor-history.vue')
const sensorAlarmSet = () => import('@/views/pages/sensor-group/alarm/sensor-alarm-set.vue')
const weatherAlarmSet = () => import('@/views/pages/sensor-group/alarm/weather-alarm-set.vue')
const setTimePeriod = () => import('@/views/pages/sensor-group/alarm/children/set-time-period.vue')

export default [
  {
    path: '/',
    redirect: '/home-page',
    meta: {}
  },
  {
    path: '/home-page',
    name: 'home',
    meta: {
      requiresAuth: true,
      layout: 'landing'
    },
    component: () => import('@/views/pages/home-page.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/errors/not-found-page.vue')
  },

  //   主页 - 传感器历史记录【从主页进入时，要传递传感器sn】
  {
    path: '/sensor-history/:sensorSn',
    name: 'sensor-history',
    component: sensorHistory,
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  },

  //   主页 - 传感器报警配置【从主页进入时，要传递传感器id】
  {
    // path: '/sensor-alarm-set/:id',
    path: '/sensor-alarm-set',
    name: 'sensor-alarm-set',
    component: sensorAlarmSet,
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  },
  // 气象站报警配置
  {
    path: '/weather-alarm-set',
    name: 'weather-alarm-set',
    component: weatherAlarmSet,
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  },
  // 时间段设置
  {
    path: '/set-time-period',
    name: 'set-time-period',
    component: setTimePeriod,
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  }
]
