
import { rp } from './http.js'

export default function handleError(Vue,) {
  Vue.config.errorHandler = (err, instance, info) => {
    let e = { message: err?.message, stack: err?.stack, code: err?.code }
    console.error('report err:', err, info, instance)
    rp.post(`/v1/report/error`, {
      err: e,
      // instance,
      info,
      location: window.location.href,
      userAgent: window.navigator.userAgent,
      file: instance.$?.type?.__file,
      name: instance.$?.type?.__name,
      note: instance.$?.vnode?.type?.__file,
      noteName: instance.$?.vnode?.type?.__name,

    }).then(res => console.log('report err ID:', res.reportId))
  }
}
