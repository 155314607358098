<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-28 11:31:32
 * @FilePath: \h5-front\src\components\navigation\main-sidebar.vue
 * @Description: 左侧菜单抽屉
-->
<template>
  <v-navigation-drawer
    border="none"
    elevation="1"
    v-model="customizeTheme.mainSidebar"
    id="mainMenu"
    :touchless="false"
  >
    <!---Top Area -->
    <template v-if="!customizeTheme.miniSidebar" v-slot:prepend>
      <a
        href="https://modao.cc/proto/E5JAgAi0s3bmq4kuru3t1A/sharing?view_mode=device&screen=rbpTtkYfjtWK26lkF&canvasId=rcTtkYfjU1befwR2sxvd4s&from=sharing"
        target="_blank"
      >
        <v-card
          style="box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 15px -20px"
          height="64"
          class="d-flex align-center justify-center"
        >
          <img v-if="customizeTheme.darkTheme" width="160" src="@/assets/logo_dark.png" alt="" />
          <img v-else="customizeTheme.darkTheme" width="160" src="@/assets/logo_light.png" alt="" />
        </v-card>
      </a>

      <!---基地操作 -->
      <bis-operate />
    </template>

    <!---Nav List -->
    <main-menu :menu="navigation.menu"></main-menu>

    <!---Bottom Area -->
    <template v-if="!customizeTheme.miniSidebar" v-slot:append>
      <v-card
        theme="dark"
        height="225"
        class="pa-3"
        variant="text"
        style="box-shadow: rgba(0, 0, 0, 0.05) 0px -25px 15px -20px"
        v-if="false"
      >
        <v-card class="d-flex flex-column gradient pa-2" :class="customizeTheme.primaryColor.colorName" height="200">
          <v-card-title>
            <v-btn class="mr-2" size="40" color="white" :class="`text-${customizeTheme.primaryColor.colorName}`" icon>
              <Icon width="30" icon="line-md:github-loop" />
            </v-btn>
            OnismYY
          </v-card-title>
          <v-card-subtitle> </v-card-subtitle>
          <v-card-text>
            <div><b>Github:</b></div>
            <div>fkdsjfkdfjkfdsjk</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="white" block prepend-icon="mdi-thumb-up-outline" variant="elevated" @click="openGithubSite">
              给我点个赞吧
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { Icon } from '@iconify/vue'
import configs from '@/configs'
import { useCustomizeThemeStore } from '@/stores/customize-theme'

const customizeTheme = useCustomizeThemeStore()
const navigation = ref(configs.navigation)

const openGithubSite = () => {
  window.open('https://baidu.com', '_blank')
}

onMounted(() => {
  scrollToBottom()
})

const scrollToBottom = () => {
  const contentArea = document.querySelector('.v-navigation-drawer__content')
  const activeItem = document.querySelector('.v-list-item--active')

  setTimeout(() => {
    contentArea?.scrollTo({
      top: activeItem?.offsetTop
    })
  }, 100)
}
</script>

<style scoped lang="scss"></style>
