/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-12-19 15:25:06
 * @FilePath: \h5-front\src\configs\menus\base-manage.js
 * @Description: 区域管理
 */
export default [
  {
      icon: "mdi-hoop-house",
      text: "menuName.zoneManage",//区域管理
      link: "/base-house/greenhouse-manage",
      name:"greenhouse-manage",
      // menuKey: ["管理员"],
  },
  {
    icon: "mdi-map-marker-path",
    text: "menuName.baseManage",//位置管理
    link: "/base-house/position-manage",
    name:"position-manage",
    // menuKey: [""],
},
]